import React, {useState, useRef, useMemo } from "react"
import { graphql } from "gatsby"
import Layout from "../components/Layout"
import * as styles from "../styles/games.module.scss"
import { useBoundingRect } from "../hooks/useBoundingRect"
import DrawingBoard from "../components/DrawingBoard"
import Webcam from  "../components/Webcam"
import favicon from "../assets/favicon.png"
import defaultImage from "../assets/default-image.jpg"

const Games = ({ data }) => {
  const games = data.prismicGame
  const [currentGame, setCurrentGame] = useState(0)
  const [isFlipped, setIsFlipped] = useState(false);
  const drawingContainerRef = useRef();
  const boxSize = useBoundingRect(drawingContainerRef);
  var lineCounter = 0;
  const [toClear, setToClear] = useState(false);
  const shuffledArray = useMemo(
    () => games.data.game.sort((a, b) => 0.5 - Math.random()),
    [games]
  );

  return (
    <Layout page={games}>
      <div className={`${styles.wrapper} page-intro`}>
        <div ref={drawingContainerRef} className={`${styles.gameContainer}`}>
            {shuffledArray.slice(currentGame, currentGame+1).map((game) => {

              const counter = () =>{
                lineCounter++;
                if(lineCounter===game.game_level){
                  setIsFlipped(!isFlipped);
                  lineCounter=0;}
              }

              return (
                  <div 
                  className={`${styles.gameInner} ${styles.gameRecto} ${isFlipped ? styles.flipped : ""}`}
                  onMouseUp={() => setTimeout(counter, 500)}
                  onTouchEnd = {() => setTimeout(counter, 500)}
                  >
                    <div className={styles.gameInstruction}>
                      { game.game_instruction.text }
                    </div>
                    

                    { game.game_cam && 
                      <Webcam />
                    }
            
                    { game.game_image.url && 
                      <img src={game.game_image.url} alt="doodle" className={styles.gameImage}/>
                    }

                    <DrawingBoard
                      mode="game"
                      dimension={boxSize}
                      level={game.game_level}
                      toClear={toClear}
                      originDrawpage={true}
                    />
                    
                  </div>
                  
              )
            })}

            <div className={`${styles.gameInner} ${styles.gameVerso} ${isFlipped ? styles.flipped : ""}`}>
              <p>
                <button className={styles.button} onClick={()=>{setIsFlipped(!isFlipped); setToClear(!toClear)}}>réessayer</button><br/>
                <button 
                className={styles.button} 
                onClick={()=>{
                  setIsFlipped(!isFlipped);
                  setToClear(!toClear);
                  if(currentGame+1 === shuffledArray.length){
                    setCurrentGame(0);
                  }
                  else{
                    setCurrentGame(currentGame+1);
                  }

                  
                }}>suivant</button>
              </p>
            </div>
        </div>
      </div>
    </Layout>
  )
}

export default Games

export const Head = ({ data }) => (
  <>
      <title>{`${data.prismicGame.lang.slice(0,2) === "fr" ? "Jeux" : "Games"} → the sun project`}</title>
      <meta name="description" content={data.prismicGame.data.seo_description.text} />
      <meta name="keywords" content={data.prismicGame.data.seo_keywords.text} />
      <meta name="author" content="the sun project" />
      
      <meta property="og:title" content={`${data.prismicGame.lang.slice(0,2) === "fr" ? "Jeux" : "Games"} → the sun project`} />
      <meta property="og:site_name" content="the sun project" />
      <meta property="og:description" content={data.prismicGame.data.seo_description.text} />
      <meta property="og:image" content={`https://thesunproject.fr${defaultImage}`} />
      <meta property="og:locale" content={data.prismicGame.lang} />
      <meta property="og:type" content="website" />

      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:site" content="the sun project" />
      <meta name="twitter:title" content={`${data.prismicGame.lang.slice(0,2) === "fr" ? "Jeux" : "Games"} → the sun project`}  />
      <meta name="twitter:description" content={data.prismicGame.data.seo_description.text} />
      <meta name="twitter:image" content={`https://thesunproject.fr${defaultImage}`} />

      <link rel="icon" type="image/png" href={favicon} />
  </>
)

export const pageQuery = graphql`
  query GameBySlug($uid: String, $lang: String) {
    prismicGame(id: { eq: $uid }, lang: { eq: $lang }) {
      lang
      uid
      type
      alternate_languages {
        id
        type
        lang
        uid
      }
      data {
        title {
          text
        }
        seo_keywords {
          text
        }
        seo_description {
          text
        }
        game {
          game_instruction{
            text
          }
          game_level
          game_image {
            url
          }
          game_cam
        }
      }
    }
  }
`
