// extracted by mini-css-extract-plugin
export var button = "games-module--button--88239";
export var flipped = "games-module--flipped--4a4ed";
export var gameCam = "games-module--game-cam--c933b";
export var gameContainer = "games-module--game-container--8a3ab";
export var gameImage = "games-module--game-image--c1079";
export var gameInner = "games-module--game-inner--1979c";
export var gameInstruction = "games-module--game-instruction--f97f0";
export var gameRecto = "games-module--game-recto--0c309";
export var gameVerso = "games-module--game-verso--97eb4";
export var wrapper = "games-module--wrapper--05b5b";